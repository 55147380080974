<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
          <v-col cols="1" md="1" class="justify-center pt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="primary--text" v-on="on" v-bind="attrs">{{
                  infoIcon
                }}</v-icon>
              </template>
              <span
                >En esta opción se debe ingresar sólo información general para conectarse al cliente.
                <br>Ejemplos:
                <br>“Conectarse vía Teamviewer. Buscar cliente en lista de ordenadores”
                <br>“Conectarse vía AnyDesk, usuario 123 456 885, contraeseña, Mcn3434”
                <br>“La conexión por RDP llega al server de datos, de ahí conectarse a 192.168.158 para ir al de aplicaciones”
                <br>Etc.
                </span
              >
            </v-tooltip>
          </v-col>
      </v-card-title>
      
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Tipo de texto -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                tabindex="2"
                v-model="tipoTexto"
                :rules="[...rules.required]"
                label="Texto"
                dense
                auto-grow
                rows="8"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-switch
                class="py-0"
                v-model="habilitado"
                label="Habilitado"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  name: "EditAccesoDatos",
  // directives: { mask },
  props: {
    registrosParams: {
      Type: Object,
      required: false,
    },
    registrosUsers: {
      Type: Array,
    },
    idReg: {
      Type: Number,
    },
  },
  data: () => ({
    formEditTitle: "",
    rules: rules,
    habilitado: true,
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    usuarioSelected: null,
    tiposUsers: [],
    esVigente: true,
    tipoTexto: null,
    signo: null,
    loadingSaveBtn: false,
    propId: null,
  }),
  created() {
    //trae los Tipos de usuarios en el select
    if (this.registrosParams) {
      this.setAccesoDatos();
    } else {
      this.newAccesoDatos();
    }
  },
  methods: {
    ...mapActions({
      getTipoUsuarios: "clientesData/getTipoUsuarios",
      saveAccesoDatos: "clientesData/saveAccesoDatos",
      setAlert: "user/setAlert",
    }),
    newAccesoDatos() {
      this.formEditTitle = "Nuevos datos de acceso";
    },
    moverAlSiguienteCampo() {
      // Establecer el enfoque manualmente en el campo de texto
      this.$refs.tipoTexto.$refs.input.focus();
    },
    async setAccesoDatos() {
      this.formEditTitle = "Editar datos de acceso";
      this.tipoTexto = this.registrosParams.datosTexto;
      this.habilitado = this.registrosParams.habilitado;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        idDatos: this.registrosParams?.idDatos ?? null,
        clienteId: this.idReg,
        datosTexto: this.tipoTexto,
        habilitado: this.habilitado,
      };
      const response = await this.saveAccesoDatos(data);
      if (response.status === 200) {
        this.loadingSaveBtn = false;
        this.closeModal();
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.loadingSaveBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped></style>
