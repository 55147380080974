var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('go-back-btn',{attrs:{"routeToGo":_vm.routeToGo}}),_c('PageHeader',{staticClass:"mb-3",attrs:{"title":(_vm.title + " " + (this.nomCliente))}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.itemsAccesoDatos,"loading":_vm.loading,"item-key":"idDatos","no-data-text":"No hay datos disponibles para mostrar","search":_vm.searchConsulta},scopedSlots:_vm._u([{key:"item.datosTexto",fn:function(ref){
var item = ref.item;
return _vm._l((item.datosTexto.split('\n')),function(line,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(line))])])})}},{key:"item.habilitado",fn:function(ref){
var item = ref.item;
return [(item.habilitado)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"10","align-self":"center"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Buscar","single-line":"","append-icon":_vm.iconoSearch},model:{value:(_vm.searchConsulta),callback:function ($$v) {_vm.searchConsulta=$$v},expression:"searchConsulta"}})],1),(_vm.canCreateDatosAcceso)?_c('v-col',{staticClass:"pt-5",attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditDatosAcceso)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar datos")])]):_vm._e(),(_vm.canDeleteDatosAcceso)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteDatos(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar datos")])]):_vm._e()]}}],null,true)})],1)],1)],1),(_vm.openModalUsers)?_c('v-dialog',{attrs:{"max-width":"40%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload.apply(null, arguments)}},model:{value:(_vm.openModalUsers),callback:function ($$v) {_vm.openModalUsers=$$v},expression:"openModalUsers"}},[_c('EditAccesoDatos',{attrs:{"idReg":_vm.idReg,"recibeId":_vm.recibeId,"itemsAccesoDatos":_vm.itemsAccesoDatos,"registrosParams":_vm.registrosParams,"regClientesId":_vm.regClientesId},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"max-width":"30%","titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal,"isLoading":_vm.isLoadingDelete},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }